<template>
  <Transition name="collapse-up">
    <slot class="will-change-[opacity,transform,backdrop-filter]" />
  </Transition>
</template>

<style lang="sass">
.collapse-up-enter-active, .collapse-up-leave-active
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1)
  overflow: hidden

.collapse-up-enter-from, .collapse-up-leave-to
  opacity: 0
  transform: translateY(10%)
  backdrop-filter: blur(0)

.collapse-up-enter-to, .collapse-up-leave-from
  opacity: 1
  transform: translateY(0)
  backdrop-filter: blur(8px)
</style>
